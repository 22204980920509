<template>
  <section class="agenda monday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <div>
            <h1 class="date__title">
              Monday, April 24th
            </h1>
            <p class="date__description">
              Pre-Registration and Welcome Happy Hour
            </p>
          </div>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="18px" height="11px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,4l-6.6,6.6c-0.1,0.1-0.3,0.2-0.4,0.3C1.9,10.9,1.7,11,1.5,11c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.3-0.4C0.2,10,0.2,9.8,0.2,9.6c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4l7.5-7.5c0.3-0.2,0.6-0.4,0.9-0.4c0.4,0,0.7,0.1,0.9,0.4l7.5,7.5c0.1,0.1,0.2,0.3,0.3,0.4C17.9,9.3,18,9.4,18,9.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.4,0.3C17,10.9,16.8,11,16.6,11c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3L9.1,4z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <div class="cards__container">
            <div class="cards__single darkblue">
              <p class="cards__title">
                Pre - Registration
              </p>
              <div class="cards__description">
                <p>4:00 PM - 7:00 PM</p>
                <p><b>Ocean Lounge</b></p>
              </div>
            </div>
            <div class="cards__single blue">
              <p class="cards__title">
                Happy Hour by SRAX
              </p>
              <div class="cards__description">
                <p>5:00 PM - 7:00 PM</p>
                <p><b>Marena</b></p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/monday.scss';
</style>

<script>

export default {
  name: 'Monday',
  data() {
    return {
      seccionVisible: true,
      isRotated: true,
      isIphone: false
    }
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>
