<template>
  <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Ocean Lounge)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Del Mar)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Indigo)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Ocean Lounge)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Del Mar)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Indigo)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards__container">
      <div class="cards__single cerulean woget"
      :class="{ 'speaker-highlight': current.getHours() === wogerHour && current.getMinutes() >= wogerMins && current.getMinutes() < wogerMins + 30 && current.getDay() === eventDay }">
        <div class="cards__speaker">
          <p class="cards__name">
            Ella Woger
          </p>
          <p class="cards__spktitle">
            Invest Puerto Rico, CEO
          </p>
        </div>
        <div class="cards__description">
          <p>4:00 PM - 4:30 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
      <div class="cards__single cerulean pierce"
      :class="{ 'speaker-highlight': current.getHours() >= pierceHour && current.getHours() < pierceHour + 1 && current.getDay() === eventDay }">
        <div class="cards__speaker">
          <p class="cards__name">
            Brock Pierce
          </p>
          <p class="cards__spktitle">
            Helios, CEO | Mystic Ventures, Managing Partner
          </p>
        </div>
        <div class="cards__description">
          <p>4:30 PM - 5:30 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
      <div class="cards__single blue"
      :class="{ 'speaker-highlight': current.getHours() >= happyHour && current.getHours() < happyHour + 2 && current.getDay() === eventDay }">
        <p class="cards__title">
          Happy Hour by<br> The Money Channel NYC
        </p>
        <div class="cards__description">
          <p>5:30 PM - 7:30 PM</p>
          <p><b>Sereno Pool</b></p>
        </div>
      </div>
      <div class="cards__single blue">
        <p class="cards__title">
          Tuesday, Dinner at District T-Mobile
        </p>
        <div class="cards__description">
          <p>8:00 PM - 10:00 PM</p>
          <p><b><a href="https://goo.gl/maps/RXMBFG2pcyoCobrp7" target="_blank">Arena Medalla</a></b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Tuesday Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '1:30 PM', end: '2:00 PM', company: 'RIOT', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Jericho Energy', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Global Crossing Airlines Group', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Terawulf', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Bruush Oral Care Inc.', hr: '15:30', duration: 29 }
      ],
      track2: [
        { start: '1:30 PM', end: '2:00 PM', company: 'Muscle Maker Inc.', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: '30 min break', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Tonix Pharma', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Lisata Therapeutics (LSTA)', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Ryvyl', hr: '15:30', duration: 29 }
      ],
      track3: [
        { start: '1:30 PM', end: '2:30 PM', company: 'Dr. Elder Session 2: How to create a trading plan', hr: '13:30', duration: 59 },
        { start: '2:30 PM', end: '3:00 PM', company: 'PainReform', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '4:00 PM', company: 'Dr. Elder Session 3: How to add technical trading tools to your fundamental decision-making', hr: '15:00', duration: 59 },
      ],
      isMobile: false,
      wogerHour: 16,
      wogerMins: 30,
      pierceHour: 16.5,
      happyHour: 17.5,
      eventDay: 2
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    isHighlight(info) {
      const now = new Date();
      const actualHr = now.getHours() * 60 + now.getMinutes();
      const hr = parseInt(info.hr.split(":")[0]) * 60 + parseInt(info.hr.split(":")[1]);
      return hr <= actualHr && hr + info.duration >= actualHr;
    },
  },
  computed: {
    current() {
      return new Date();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
