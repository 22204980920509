<template>
  <div id="app">
    <Menu />
    <router-view/>
    <Footer />
  </div>
</template>

<style lang="scss">
  @import '@/styles/style.scss';
</style>

<script>
import Menu from '@/components/menu';
import Footer from '@/components/footer';
import scrollingActions from '@/util/scrollingActions';

export default {
  name: 'app',
  data() {
    return {
    };
  },
  components: {
    Menu,
    Footer
  },
  methods: {

  },
  async mounted() {
    scrollingActions();
    window.onload = () => { scrollingActions(); };
    window.onscroll = () => { scrollingActions(); };
  },
};
</script>
