<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single cobalt noheight"
      :class="{ 'speaker-highlight': current.getHours() === lunchHour && current.getMinutes() >= lunchMins && current.getMinutes() < lunchMins + 30 && current.getDay() === eventDay }">
        <p class="cards__title">
          Lunch Break
        </p>
        <div class="cards__description">
          <p>12:00 PM - 12:30 PM</p>
          <p><b>Nereidas Pre Function</b></p>
        </div>
      </div>
      <div class="cards__single cerulean lopresti"
      :class="{ 'speaker-highlight': current.getHours() >= loPrestiHour && current.getHours() < loPrestiHour + 1 && current.getDay() === eventDay }">
        <div class="cards__speaker">
          <p class="cards__name">
            Marc LoPresti
          </p>
          <p class="cards__spktitle">
            IDI Group, Founder & Managing Director
          </p>
        </div>
        <div class="cards__description">
          <p>12:30 PM - 1:30 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Wednesday Lunch',
  data() {
    return {
      lunchHour: 12,
      lunchMins: 0,
      loPrestiHour: 12.5,
      eventDay: 3
    }
  },
  computed: {
    current() {
      return new Date();
    }
  },
};
</script>