<template>
  <header class="main-menu">
    <div class="container">
      <section class="main-menu__logo">
        <a href="https://puertorico.srax.com/" target="_blank" class="logo">
          <img src="../assets/logo.svg">
        </a>
        <a href="/Agenda_PuertoRico.pdf" class="download-link" download>Download PDF</a>
      </section>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/menu.scss';
</style>

<script>

export default {
  name: 'menu',
  data() {
    return {
      
    }
  },
};
</script>