<template>
  <main id="home">
    <monday />
    <tuesday />
    <wednesday />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import monday from '@/components/monday.vue';
import tuesday from '@/components/tuesday.vue';
import wednesday from '@/components/wednesday.vue';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    monday,
    tuesday,
    wednesday
  },
  methods: {
  },
  mounted() {
  },
};
</script>
