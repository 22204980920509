<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single cobalt cmf"
      :class="{ 'speaker-highlight': current.getHours() === lunchHour && current.getMinutes() >= lunchMins && current.getMinutes() < lunchMins + 20 && current.getDay() === eventDay }">
        <p class="cards__title">
          Lunch by Carmel, Milazzo & Feil LLP
        </p>
        <div class="cards__description">
          <p>12:00 PM - 12:20 PM</p>
          <p><b>Nereidas Pre Function</b></p>
        </div>
      </div>
      <div class="desktop-only">
        <br>
      </div>
      <div class="cards__single cerulean parlikar"
      :class="{ 'speaker-highlight': current.getHours() === medhaHour && current.getMinutes() >= medhaMins && current.getMinutes() < medhaMins + 20 && current.getDay() === eventDay }">
        <div class="cards__speaker">
          <p class="cards__name">
            Medha Parlikar
          </p>
          <p class="cards__spktitle">
            CasperLabs, Co-Founder & CTO
          </p>
        </div>
        <div class="cards__description">
          <p>12:25 PM - 12:45 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
      <div class="cards__single cerulean elder"
      :class="{ 'speaker-highlight': 
        (current.getHours() === elderHour && current.getMinutes() >= elderMins) || 
        (current.getHours() === elderHour + 1 && current.getMinutes() < 25) &&
        current.getDay() === eventDay
      }">
        <div class="cards__speaker">
          <p class="cards__name">
            Dr. Alexander Elder
          </p>
          <p class="cards__spktitle">
            Trader & Author of Best Selling Trading Books
          </p>
        </div>
        <div class="cards__description">
          <p>12:45 PM - 1:25 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tuesday Lunch',
  data() {
    return {
      medhaHour: 12,
      elderHour: 12,
      lunchHour: 12,
      lunchMins: 0,
      medhaMins: 25,
      elderMins: 45,
      eventDay: 2
    }
  },
  computed: {
    current() {
      return new Date();
    }
  },
};
</script>