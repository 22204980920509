<template>
  <div class="cards__container">
    <div class="cards__single darkblue">
      <p class="cards__title">
        Registration
      </p>
      <div class="cards__description">
        <p>8:00 AM - 7:00 PM</p>
        <p><b>Ocean Lounge</b></p>
      </div>
    </div>
    <div class="cards__single cobalt"
    :class="{ 'speaker-highlight': current.getHours() >= breakfastHour && current.getHours() < breakfastHour + 1 && current.getDay() === eventDay }">
      <p class="cards__title">
        Breakfast
      </p>
      <div class="cards__description">
        <p>8:00 AM - 9:00 AM</p>
        <p><b>Nereidas Pre Function</b></p>
      </div>
    </div>
    <div class="cards__single cerulean sosnoff"
    :class="{ 'speaker-highlight': current.getHours() >= sosnoffHour && current.getHours() < sosnoffHour + 1 && current.getDay() === eventDay }">
      <div class="cards__speaker">
        <p class="cards__name">
          Tom Sosnoff
        </p>
        <p class="cards__spktitle">
          tasty<i>live</i>, founder & CEO | Dough, Inc. Founder
        </p>
      </div>
      <div class="cards__description">
        <p>9:00 AM - 10:00 AM</p>
        <p><b>Nereidas</b></p>
      </div>
    </div>
    <div class="cards__single darkblue noheight">
      <p class="cards__title">
        1:1 Meetings & Trade Booths
      </p>
      <div class="cards__description">
        <p>Opens at 10:00 AM</p>
        <p><b>Atlantico</b></p>
      </div>
    </div>
  </div>
  <div class="morning">
    <h2 class="subtitle">
      Morning Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Ocean Lounge)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Del Mar)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Indigo)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Ocean Lounge)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Del Mar)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Indigo)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Tuesday-Morning',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Bion Environmental', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'TAG Oil Ltd.', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Lightwave Logic', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'MySize', hr: '11:30', duration: 29 }
      ],
      track2: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Sekur', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'Guerrilla', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Bit Digital', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Biolargo', hr: '11:30', duration: 29 }
      ],
      track3: [
        { start: '10:00 AM', end: '11:00 AM', company: 'Dr. Elder Session 1: Psychology and technology of technical trading', hr: '10:00', duration: 59 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Casper Labs', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: '30 min break', hr: '11:30', duration: 29 }
      ],
      isMobile: false,
      breakfastHour: 8,
      sosnoffHour: 9,
      eventDay: 2
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    isHighlight(info) {
      const now = new Date();
      const actualHr = now.getHours() * 60 + now.getMinutes();
      const hr = parseInt(info.hr.split(":")[0]) * 60 + parseInt(info.hr.split(":")[1]);
      return hr <= actualHr && hr + info.duration >= actualHr;
    },
  },
  computed: {
    current() {
      return new Date();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
