<template>
  <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Ocean Lounge)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Del Mar)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Indigo)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Ocean Lounge)
          </div>
          <div class="--slot t1" v-for="info in track1" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Del Mar)
          </div>
          <div class="--slot t2" v-for="info in track2" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Indigo)
          </div>
          <div class="--slot t3" v-for="info in track3" :key="info.company" :class="{ highlight: isHighlight(info) && current.getDay() === eventDay }">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="cards__container">
      <div class="cards__single cerulean miglino"
      :class="{ 'speaker-highlight': current.getHours() >= miglinoHour && current.getHours() < miglinoHour + 1 && current.getDay() === eventDay }">
        <div class="cards__speaker">
          <p class="cards__name">
            Chris Miglino
          </p>
          <p class="cards__spktitle">
            SRAX, CEO
          </p>
        </div>
        <div class="cards__description">
          <p>4:00 PM - 5:00 PM</p>
          <p><b>Nereidas</b></p>
        </div>
      </div>
      <div class="cards__single blue noheight">
        <p class="cards__title">
          Closing Party at Old San Juan
        </p>
        <div class="cards__description">
          <p>Starting at 8:00 PM</p>
          <p><b><a href="https://goo.gl/maps/c3cmby2JsDsTKc9R7" target="_blank">Monastery Art Suites</a></b></p>
        </div>
      </div>
    </div>
    <div class="download">
      <a href="/Agenda_PuertoRico.pdf" class="download-link" download>Download PDF</a>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Wednesday Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '1:30 PM', end: '2:00 PM', company: 'Bitmine Immersion Technologies', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Vox Royalty', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'John Divine, BlockFills: Risk Management & Real Yield with Options', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Inbox Beverage / AIM Group', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: "Puerto Rico's Investment Landscape Panel by Uncommon EntrePReneurs", hr: '15:30', duration: 29 }
      ],
      track2: [
        { start: '1:30 PM', end: '2:00 PM', company: 'Beeline', hr: '13:30', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Golden Cariboo Resources', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Algernon Pharmaceuticals', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Bactech Environmental Corp', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Sí DAO', hr: '15:30', duration: 29 }
      ],
      track3: [
        { start: '1:30 PM', end: '2:30 PM', company: 'Dr. Elder Session 2: The granular tools of financial trading', hr: '13:30', duration: 59 },
        { start: '2:30 PM', end: '3:00 PM', company: '30 min break', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '4:00 PM', company: 'Dr. Elder Session 3: Risk management for financial traders', hr: '15:00', duration: 59 },
      ],
      isMobile: false,
      miglinoHour: 16,
      eventDay: 3
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    isHighlight(info) {
      const now = new Date();
      const actualHr = now.getHours() * 60 + now.getMinutes();
      const hr = parseInt(info.hr.split(":")[0]) * 60 + parseInt(info.hr.split(":")[1]);
      return hr <= actualHr && hr + info.duration >= actualHr;
    },
  },
  computed: {
    current() {
      return new Date();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};

</script>