<template>
  <section class="agenda tuesday">
    <div class="agenda__content container">
      <div class="agenda__date">
        <div class="date__container">
          <h1 class="date__title">
            Tuesday, April 25th
          </h1>
          <button class="rotate-btn" v-bind:class="{ 'is-rotated': isRotated }"
          @click="seccionVisible = !seccionVisible" v-on:click="toggleRotation">
            <svg :class="{ 'iphone': isIphone }" width="18px" height="11px" viewBox="0 0 18 11">
              <path fill="#3AADCC" d="M9.1,4l-6.6,6.6c-0.1,0.1-0.3,0.2-0.4,0.3C1.9,10.9,1.7,11,1.5,11c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.3-0.4C0.2,10,0.2,9.8,0.2,9.6c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4l7.5-7.5c0.3-0.2,0.6-0.4,0.9-0.4c0.4,0,0.7,0.1,0.9,0.4l7.5,7.5c0.1,0.1,0.2,0.3,0.3,0.4C17.9,9.3,18,9.4,18,9.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.4,0.3C17,10.9,16.8,11,16.6,11c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3L9.1,4z"/>
            </svg>
          </button>
        </div>
        <hr class="date__line">
      </div>
      <transition name="fade">
        <div v-show="seccionVisible">
          <tuesdaymorning/>
          <tuesdaylunch />
          <tuesdayafternoon />
        </div>
      </transition>
    </div>
  </section>
</template>

<style lang="scss">
  @import '@/styles/components/tuesday.scss';
</style>

<script>
import tuesdaymorning from '@/components/tuesday-morning.vue';
import tuesdaylunch from '@/components/tuesday-lunch.vue';
import tuesdayafternoon from '@/components/tuesday-afternoon.vue';

export default {
  name: 'Tuesday',
  data() {
    return {
      seccionVisible: true,
      isRotated: true,
      isIphone: false
    }
  },
  components: {
    tuesdaymorning,
    tuesdaylunch,
    tuesdayafternoon
  },
  methods: {
    toggleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  mounted() {
    this.isIphone = /iPhone/.test(navigator.userAgent);
  }
};
</script>