<template>
  <footer class="main-footer">
    <div class="footer__content container">
      <hr>
      <section class="logo container">
        <div class="logo__container">
          <a href="https://srax.com/" target="_blank">
            <img src="../assets/logo.png" alt="Sequire Logo">
          </a>
        </div>
        <div class="logo__text">
          <p>Want to hear more? Send us an email, <a href="mailto:puertorico@srax.com">puertorico@srax.com</a></p>
        </div>
      </section>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/footer.scss';
</style>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
};
</script>